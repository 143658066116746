@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;500;700;800;900&display=swap");
.App {
  font-family: "Inter", sans-serif;
  padding: 0px;
  margin: 0px;
}

a,
a:visited {
  color: inherit;
  text-decoration: none;
  margin: 0px auto;
  text-align: inherit;
}
